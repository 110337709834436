import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/HomeLayout.jsx";
import ExternalLink from '@components/ExternalLink/ExternalLink';
import Spacer from '@components/Spacer/Spacer';
import * as styles from '@layouts/HomeLayout.module.scss';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Sensicast`}</h1>
    <div className={styles.workDetails}>
  Intern &#10142; QA &#10142; Hardware Hacker &#10142; Software Engineer
  <br />
  Needham, MA &mdash; 2003&#8202;&ndash;&#8202;2007 (summer, winter breaks)
    </div>
    <Spacer height={16} mdxType="Spacer" />
    <p>{`Joined as an intern tasked with soldering hardware, assembling cables and connectors, testing firmware and software, and hardware testing in extreme environments (high heat, cold, long-range, and noisy radio environments).`}</p>
    <p>{`Gained more responsibility over the next four years, working on fixing bugs, configuring and deploying wireless mesh networks in customer locations, and developing web, desktop, and mobile applications using PHP, Javascript, MySQL, embedded C, C#, and Windows Mobile.`}</p>
    <h2>{`Technology`}</h2>
    <ul>
      <li parentName="ul">{`Low-power wireless mesh sensor network running on a proprietary 802.15.4 protocol`}</li>
      <li parentName="ul">{`MSP430, ATmega128`}</li>
    </ul>
    <h2>{`Links`}</h2>
    <ul>
      <li parentName="ul"><ExternalLink href="https://web.archive.org/web/20140903185416/http://www.crunchbase.com/organization/sensicast-systems" mdxType="ExternalLink">
  Crunchbase
        </ExternalLink></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      